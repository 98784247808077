@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");
:root {
  --font-family: "Inter", sans-serif;
  --Extra-Light: 200;
  --Light: 300;
  --Regular: 400;
  --Medium: 500;
  --Semi-Bold: 600;
  --Bold: 700;
  --Extra-Bold: 800;
  --Hyperlink-Color: #6c459b;
  --Dark-Font: #f2f2f2;
  --Dark-Background: rgba(4, 5, 13, 0.9);
}

* {
  font-family: var(--font-family);
  padding: 0px;
  margin: 0px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--Semi-Bold);
  line-height: 26px;
  padding: 24px 0px 8px 0px;
}

p,
ul,
li {
  font-weight: var(--Regular);
  line-height: 28px;
  font-size: 15px;
}

small {
  font-weight: var(--Light);
  font-size: 14px;
  line-height: 22px;
}

a,
Link {
  text-decoration: none;
  color: var(--Hyperlink-Color);
  font-weight: var(--Semi-Bold);
}

.App {
  transition: 0.45s ease-in-out;
}

.dark-mode {
  color: var(--Dark-Font);
  background-color: var(--Dark-Background);
  transition: 0.45s ease-in-out;
}
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6 {
  color: #5b2c87;
}
.dark-mode .mode-switch {
  background-color: var(--Dark-Background);
  border-color: var(--Dark-Font);
  transform: scale(1.35);
  transition: transform 0.1s;
}
.dark-mode .socials * {
  color: #5b2c87;
}
.dark-mode .contact-collaborate {
  background-color: transparent;
}
.dark-mode .mobile-header {
  background-color: transparent;
}
.dark-mode .mobile-header .header-real-content .socials * {
  color: #f2f2f2;
}

.mode-switch {
  border: 1px solid var(--Dark-Background);
  border-radius: 100%;
  height: 22px;
  width: 22px;
  outline: none;
  border-style: dashed;
  background-color: var(--Dark-Font);
  border-color: var(--Dark-Background);
  transform: scale(1);
  transition: transform 0.1s;
  cursor: pointer;
  margin-right: 12px;
  margin-top: 10px;
}

.mobile-header {
  border-top: 7.5px solid #5b2c87;
  border-top-color: #5b2c87;
  width: 100%;
  min-height: auto;
  background-color: rgba(242, 242, 242, 0.55);
}
.mobile-header nav {
  text-align: right;
  display: flex;
  padding: 15px;
  justify-content: flex-end;
  align-items: center;
}
.mobile-header .header-real-content {
  text-align: center;
  padding: 20px 0px 40px;
}
.mobile-header .header-real-content img {
  width: 80px;
  height: auto;
  border-radius: 8px;
  border: 2px solid #bdbfbf;
}
.mobile-header .header-real-content h3 {
  line-height: 0.5;
}
.mobile-header .header-real-content .socials {
  margin-top: 20px;
}
.mobile-header .header-real-content .socials * {
  margin: 0px 5px;
  font-size: 20px;
  color: var(--Dark-Background);
}

.full-body {
  padding: 15px;
}

article {
  margin-bottom: 35px;
}
article p {
  padding-top: 18px;
}

.portfolio__projects .project-tile {
  border: 1px solid #bdbfbf;
  border-radius: 9px;
  padding: 20px;
  margin: 20px 0px;
}
.portfolio__projects h5 {
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.portfolio__projects .project-tile-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.portfolio__projects .project-tile-header p {
  display: inline-flex;
  align-items: center;
}
.portfolio__projects .project-tile-header p * {
  margin: 0px 5px;
}
.portfolio__projects .project-tile-header * {
  padding: 0px;
  margin: 0px;
  color: #5b2c87;
}
.portfolio__projects .project-tile-image {
  width: 100%;
  height: auto;
  margin: 10px 0px 30px;
}
.portfolio__projects .project-tile-image img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.portfolio__projects .project-tile-link {
  display: inline-flex;
  margin-top: 15px;
}
.portfolio__projects .project-tile-link a {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}
.portfolio__projects .project-tile-link a * {
  margin-right: 5px;
}

ul {
  margin-bottom: 35px;
}
ul li {
  padding-top: 18px;
}

.portfolio__projects > div .project-tile-image {
  margin-top: 40px;
}
.collaborate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.collaborate span {
  margin-right: 15px;
}

.contact-collaborate {
  text-align: center;
  margin-top: 20px;
  padding: 40px 0px;
  width: 100%;
  height: auto;
  background-color: rgba(242, 242, 242, 0.55);
}
.contact-collaborate p {
  font-size: 20px;
  padding-top: 15px;
}
.contact-collaborate h2 {
  position: relative;
  margin: 0px;
  padding: 0px 0px 10px 0px;
}
.contact-collaborate h2 ::before {
  position: absolute;
  width: 10%;
  height: 2px;
  background-color: #6c459b;
  content: "";
  bottom: 0px;
  right: 175px;
}

.error-profile {
  display: block;
  height: 100vh;
  width: 100%;
  text-align: center;
}
.error-profile h3 {
  color: #6c459b;
}
.error-profile article p {
  margin-top: 2px;
  padding: 2px;
  line-height: 24px;
}

@media only screen and (max-width: 768px) {
  .profile-page {
    display: block;
  }
  .error-profile {
    display: none;
  }
}
.Footer {
  text-align: center;
  margin-top: 30px;
}
.Footer h6 {
  font-weight: 300;
}/*# sourceMappingURL=profile.css.map */