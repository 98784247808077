@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "Inter", sans-serif;

  //Font-Weights
  --Extra-Light: 200;
  --Light: 300;
  --Regular: 400;
  --Medium: 500;
  --Semi-Bold: 600;
  --Bold: 700;
  --Extra-Bold: 800;

  //Colors
  --Hyperlink-Color: #6c459b;

  //Dar Mode Colors
  --Dark-Font: #f2f2f2;
  --Dark-Background: rgba(4, 5, 13, 0.9);
}

* {
  font-family: var(--font-family);
  padding: 0px;
  margin: 0px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  scroll-behavior: smooth;
}

body > div > div {
  //padding: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--Semi-Bold);
  line-height: 26px;
  padding: 24px 0px 8px 0px;
}

p,
ul,
li {
  font-weight: var(--Regular);
  line-height: 28px;
  font-size: 15px;
}

small {
  font-weight: var(--Light);
  font-size: 14px;
  line-height: 22px;
}

a,
Link {
  text-decoration: none;
  color: var(--Hyperlink-Color);
  font-weight: var(--Semi-Bold);
}

.App {
  transition: 0.45s ease-in-out;
}

.dark-mode {
  color: var(--Dark-Font);
  background-color: var(--Dark-Background);
  transition: 0.45s ease-in-out;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #5b2c87;
  }

  .mode-switch {
    background-color: var(--Dark-Background);
    border-color: var(--Dark-Font);
    transform: scale(1.35);
    transition: transform 0.1s;
  }

  .socials * {
    color: #5b2c87;
  }

  .contact-collaborate {
    background-color: transparent;
  }

  .mobile-header {
    background-color: transparent;
    .header-real-content {
      .socials * {
        color: #f2f2f2;
      }
    }
  }
}

.mode-switch {
  border: 1px solid var(--Dark-Background);
  border-radius: 100%;
  height: 22px;
  width: 22px;
  outline: none;
  border-style: dashed;
  background-color: var(--Dark-Font);
  border-color: var(--Dark-Background);
  transform: scale(1);
  transition: transform 0.1s;
  cursor: pointer;
  margin-right: 12px;
  margin-top: 10px;
}

//Header Components

.mobile-header {
  border-top: 7.5px solid #5b2c87;
  border-top-color: #5b2c87;
  width: 100%;
  min-height: auto;
  background-color: rgba(242, 242, 242, 0.55);

  nav {
    text-align: right;
    display: flex;
    padding: 15px;
    justify-content: flex-end;
    align-items: center;
  }

  .header-real-content {
    text-align: center;
    padding: 20px 0px 40px;

    img {
      width: 80px;
      height: auto;
      border-radius: 8px;
      border: 2px solid #bdbfbf;
    }

    h3 {
      line-height: 0.5;
    }

    .socials {
      margin-top: 20px;
    }

    .socials * {
      margin: 0px 5px;
      font-size: 20px;
      color: var(--Dark-Background);
    }
  }
}

//Full Body
.full-body {
  padding: 15px;
}

article {
  margin-bottom: 35px;
  p {
    padding-top: 18px;
  }
}

.portfolio__projects {
  .project-tile {
    border: 1px solid #bdbfbf;
    border-radius: 9px;
    padding: 20px;
    margin: 20px 0px;
  }

  h5 {
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  .project-tile-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    p {
      display: inline-flex;
      align-items: center;

      * {
        margin: 0px 5px;
      }
    }
  }

  .project-tile-header * {
    padding: 0px;
    margin: 0px;
    color: #5b2c87;
  }

  .project-tile-image {
    width: 100%;
    height: auto;
    margin: 10px 0px 30px;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }
  }

  .project-tile-link {
    display: inline-flex;
    margin-top: 15px;

    a {
      display: inline-flex;
      align-items: center;
      margin-right: 10px;

      * {
        margin-right: 5px;
      }
    }
  }
}

ul {
  margin-bottom: 35px;
  li {
    padding-top: 18px;
  }
}

.portfolio__projects > div {
  //background-color: rgb(108, 70, 155);
  //color: var(--Dark-Font);

  .project-tile-header * {
    //color: var(--Dark-Font);
  }

  .project-tile-image {
    margin-top: 40px;
  }

  a {
    //color: var(--Dark-Font);
  }
}

.collaborate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  span {
    margin-right: 15px;
  }
}

.contact-collaborate {
  text-align: center;
  margin-top: 20px;
  padding: 40px 0px;
  width: 100%;
  height: auto;
  background-color: rgba(242, 242, 242, 0.55);

  p {
    font-size: 20px;
    padding-top: 15px;
  }

  h2 {
    position: relative;
    margin: 0px;
    padding: 0px 0px 10px 0px;

    ::before {
      position: absolute;
      width: 10%;
      height: 2px;
      background-color: #6c459b;
      content: "";
      bottom: 0px;
      right: 175px;
    }
  }
}

.profile-page {
  //display: none;
}

.error-profile {
  display: block;
  height: 100vh;
  width: 100%;
  text-align: center;

  h3 {
    color: #6c459b;
  }

  article {
    p {
      margin-top: 2px;
      padding: 2px;
      line-height: 24px;
    }
  }
}

// .error-profile, main{
//     display: grid;
//     place-content: center;
// }

@media only screen and (max-width: 768px) {
  .profile-page {
    display: block;
  }

  .error-profile {
    display: none;
  }
}

.Footer {
  text-align: center;
  margin-top: 30px;

  h6 {
    font-weight: 300;
  }
}
